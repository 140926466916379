import React, { useState, useEffect, useRef, useCallback } from 'react';
import ResourcePanel from './ResourcePanel';
import SoundPanel from './SoundPanel';
import  './GameField.css';

// Функція для завантаження зображень
const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
  });
};

// Функція для створення анімації
const createAnimation = (images, duration) => {
  let frame = 0;
  const frameInterval = duration / images.length;
  let elapsedTime = 0;

  const update = (deltaTime) => {
    elapsedTime += deltaTime;
    while (elapsedTime >= frameInterval) {
      frame = (frame + 1) % images.length;
      elapsedTime -= frameInterval;
    }
  };

  const getCurrentImage = () => images[frame];

  return { update, getCurrentImage };
};

const GameCanvas = () => {
  const [tents, setTents] = useState([]);
  const [houses, setHouses] = useState([]);
  const [selectedCell, setSelectedCell] = useState(null);
  const canvasRef = useRef(null);
  const columns = 20;
  const rows = 20;
  const cellSize = 200;

  const [backgroundImage, setBackgroundImage] = useState(null);
  const animationsRef = useRef({});
  const lastTimeRef = useRef(0);
  const [zoomLevel, setZoomLevel] = useState(1); // Зум
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [canvasOffset, setCanvasOffset] = useState({ x: 0, y: 0 });

  const [bgVolume, setBgVolume] = useState(1);
  const [effectsVolume, setEffectsVolume] = useState(1);
  const [overallVolume, setOverallVolume] = useState(1);
  const [soundEnabled, setSoundEnabled] = useState(false); // Додаємо стан для контролю звуку

  const [houseTypes, setHouseTypes] = useState(new Set());

   // Звуки для різних типів об'єктів
   const soundsRef = useRef({
    backgroundMusic: new Audio('./assets/sounds/theme_winter.mp3'),
    tent: new Audio('./assets/sounds/park.mp3'),
    small: new Audio('./assets/sounds/dog_2.mp3'),
    medium: new Audio('./assets/sounds/pig.mp3'),
    large1:new Audio('./assets/sounds/fountain.mp3'),
    large2: new Audio('./assets/sounds/cat.mp3'),
  });

  const soundVolumes = useRef({
    backgroundMusic: 1, // значення від 0 до 1
    tent: 1,
    small: 0.5,
    medium: 0.2,
    large1: 0.2,
    large2: 0.2,
  });

   const toggleSound = () => {
    setSoundEnabled(prevState => !prevState);
  };


  useEffect(() => {

    Object.keys(soundsRef.current).forEach(key => {
      if (key === 'backgroundMusic'){
        const audio = soundsRef.current['backgroundMusic'];
        audio.loop = true;
        audio.volume = soundEnabled ? bgVolume * overallVolume : 0;
        soundEnabled ? audio.play().catch(error => console.error('Error playing background audio:', error)) : audio.pause();
      }
      else{   
        if(houseTypes.has(key) || houseTypes.has('large')){ 
          const sound = soundsRef.current[key];
          sound.loop = true;
          sound.volume = soundVolumes.current[key] * effectsVolume * overallVolume; 
          soundEnabled ? sound.play().catch(error => console.error('Error playing background sound:', error)) :sound.pause();
        }
      }
    });

  }, [bgVolume, effectsVolume, overallVolume, soundEnabled,houseTypes]);

  useEffect(() => {
    const fetchTents = async () => {
      try {
        const response = await fetch('https://play-and-donate.online/api/tents');
        const data = await response.json();
        setTents(data);
      } catch (error) {
        console.error('Error fetching tents:', error);
      }
    };

    const fetchHouses = async () => {
      try {
        const response = await fetch('https://play-and-donate.online/api/houses');
        const data = await response.json();
        setHouses(data);
      } catch (error) {
        console.error('Error fetching houses:', error);
      }
    };

    fetchTents();
    fetchHouses();

    const intervalId = setInterval(() => {
      fetchTents();
      fetchHouses();
    }, 30000); 

    return () => clearInterval(intervalId);

  }, []);
  
  
  const addHouseType = (type) => {
    setHouseTypes(prevTypes => new Set(prevTypes).add(type));
  };

  useEffect(() => {
    if (houses.length > 0) {
      houses.forEach(house => {
        addHouseType(house['house_type']);
      });
    } 
  }, [houses]);

  function wrapText(ctx, text, x, y, maxWidth, lineHeight) {
    const words = text.split(' ');
    let line = '';
    let lineY = y;
    let totalHeight = 0;
  
    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + ' ';
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;
  
      if (testWidth > maxWidth && i > 0) {
        ctx.fillText(line, x, lineY);
        line = words[i] + ' ';
        lineY += lineHeight;
        totalHeight += lineHeight;  // Increase height for each line
      } else {
        line = testLine;
      }
    }
    ctx.fillText(line, x, lineY);
    totalHeight += lineHeight;  // Add height for the last line
  
    return totalHeight;  // Return the total height of the wrapped text
  }  

  useEffect(() => {
    const loadImages = async () => {
      try {
        // const bgImage = await loadImage('./assets/images/background.png');
        const bgImage = await loadImage('./assets/images/winter_background.png');
        setBackgroundImage(bgImage);

        const tentImages = await Promise.all([
          // loadImage('./assets/images/tent_1.png'),
          // loadImage('./assets/images/tent_2.png'),

          loadImage('./assets/images/winter_tent_1.png'),
          loadImage('./assets/images/winter_tent_2.png'),
          loadImage('./assets/images/winter_tent_3.png'),
          loadImage('./assets/images/winter_tent_4.png'),
          loadImage('./assets/images/winter_tent_5.png'),
        ]);

        const houseImages = {
          small: await Promise.all([
            // Стандартний вигляд

            // loadImage('./assets/images/small_house_1.png'),
            // loadImage('./assets/images/small_house_2.png'),
            // loadImage('./assets/images/small_house_3.png'),
            // loadImage('./assets/images/small_house_4.png'),

            // Зимовий вигляд
            loadImage('./assets/images/winter_small_house1.png'),
            loadImage('./assets/images/winter_small_house2.png'),
            loadImage('./assets/images/winter_small_house3.png'),
            loadImage('./assets/images/winter_small_house4.png'),
            loadImage('./assets/images/winter_small_house5.png'),
            loadImage('./assets/images/winter_small_house6.png'),
          ]),
          medium: await Promise.all([
            loadImage('./assets/images/winter_medium_house_1.png'),
            loadImage('./assets/images/winter_medium_house_2.png'),
            loadImage('./assets/images/winter_medium_house_3.png'),
            loadImage('./assets/images/winter_medium_house_4.png'),
            loadImage('./assets/images/winter_medium_house_5.png'),
          ]),
          large: await Promise.all([
            loadImage('./assets/images/winter_large_house_1.png'),
            loadImage('./assets/images/winter_large_house_2.png'),
            loadImage('./assets/images/winter_large_house_3.png'),
            loadImage('./assets/images/winter_large_house_4.png'),
            loadImage('./assets/images/winter_large_house_5.png'),
            loadImage('./assets/images/winter_large_house_6.png'),
            loadImage('./assets/images/winter_large_house_7.png'),
            loadImage('./assets/images/winter_large_house_8.png'),
            loadImage('./assets/images/winter_large_house_9.png'),
            loadImage('./assets/images/winter_large_house_10.png'),
          ]),
        };

        animationsRef.current = {
          tent: createAnimation(tentImages, 1000 / 2), // 2 кадри на секунду
          small: createAnimation(houseImages.small, 2000), // 2 секунди
          medium: createAnimation(houseImages.medium, 2000), // 2.5 секунди
          large: createAnimation(houseImages.large, 3000), // 5 секунд
        };
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    loadImages();
  }, [tents, houses]);

  const updateAnimations = useCallback((deltaTime) => {
    const animations = animationsRef.current;
    for (const anim of Object.values(animations)) {
      if (anim) {
        anim.update(deltaTime);
      }
    }
  }, []);

  const drawGrid = useCallback((ctx) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    ctx.save();
    ctx.translate(canvasOffset.x, canvasOffset.y);
    ctx.scale(zoomLevel, zoomLevel); // Додано масштабування

    if (backgroundImage) {
      const canvasWidth = ctx.canvas.width;
      const canvasHeight = ctx.canvas.height;
      const imageWidth = backgroundImage.width;
      const imageHeight = backgroundImage.height;
      for (let x = 0; x < canvasWidth; x += imageWidth) {
        for (let y = 0; y < canvasHeight; y += imageHeight) {
          ctx.drawImage(backgroundImage, x, y);
        }
      }
    }

    const animations = animationsRef.current;

    tents.forEach(tent => {
      const x = tent.cell_id[0] * cellSize;
      const y = tent.cell_id[1] * cellSize;
      const img = animations.tent?.getCurrentImage();
      if (img) {
        ctx.drawImage(img, x, y, cellSize, cellSize);
      }
    });

    houses.forEach(house => {
      const x = house.cell_id[0] * cellSize;
      const y = house.cell_id[1] * cellSize;
      const houseType = house.house_type;
      const anim = animations[houseType];
      const img = anim?.getCurrentImage();
      if (img) {
        ctx.drawImage(img, x, y, cellSize, cellSize);
      }
    });

    if (selectedCell) {
      const { col, row, centerX, centerY, tent, house } = selectedCell;

      const scaledCenterX = centerX ;
      const scaledCenterY = centerY ;
      const scaledRectWidth = 200 ;
      const scaledRectHeight = 150 ;
      const scaledTextX = (centerX - 90) ;
      const scaledTextY = (centerY - 30) ;
      const lineHeight = 20;  // Scale line height for text wrapping

      // Draw the rectangle adjusted for zoom
      ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
      ctx.fillRect(scaledCenterX - (scaledRectWidth / 2), scaledCenterY - (scaledRectHeight / 2), scaledRectWidth, scaledRectHeight);

      // Draw text inside the rectangle adjusted for zoom
      ctx.fillStyle = 'white';
      ctx.font = `${14 * zoomLevel}px Arial`;  // Scale the font size based on zoom
      ctx.fillText(`Position: [${col}, ${row}]`, scaledTextX, scaledTextY);

      if (tent) {
        let textY = scaledTextY + lineHeight;  // Starting Y position for the first text block
      
        // User name
        textY += wrapText(ctx, `User name: ${tent.user_name}`, scaledTextX, textY, scaledRectWidth - 20, lineHeight);
      
        // Income
        textY += wrapText(ctx, `Income: ${tent.income}`, scaledTextX, textY, scaledRectWidth - 20, lineHeight);
      
        // Comment
        wrapText(ctx, `Comment: ${tent.comment}`, scaledTextX, textY, scaledRectWidth - 20, lineHeight);
      
      } else if (house) {
        let textY = scaledTextY + lineHeight;  // Starting Y position for the first text block
      
        // User name
        textY += wrapText(ctx, `User name: ${house.user_name}`, scaledTextX, textY, scaledRectWidth - 20, lineHeight);
      
        // Income
        textY += wrapText(ctx, `Income: ${house.income}`, scaledTextX, textY, scaledRectWidth - 20, lineHeight);
      
        // Comment
        wrapText(ctx, `Comment: ${house.comment}`, scaledTextX, textY, scaledRectWidth - 20, lineHeight);
      } else {
        ctx.fillText('Empty cell', scaledTextX, scaledTextY + lineHeight);
      }
    }
    ctx.strokeStyle = '#ddd';        
    ctx.lineWidth = 1;
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < columns; col++) {
        ctx.strokeRect(col * cellSize, row * cellSize, cellSize, cellSize);
      }
    }

    ctx.restore();
  }, [tents, houses, selectedCell, backgroundImage, canvasOffset, zoomLevel]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const animationLoop = (time) => {
      const deltaTime = time - lastTimeRef.current;
      lastTimeRef.current = time;

      updateAnimations(deltaTime);
      drawGrid(ctx);

      requestAnimationFrame(animationLoop);
    };

    requestAnimationFrame(animationLoop);
  }, [drawGrid, updateAnimations]);

  const handleClick = (event, row, col) => {
    const tent = tents.find(tent => tent.cell_id[0] === col && tent.cell_id[1] === row);
    const house = houses.find(house => house.cell_id[0] === col && house.cell_id[1] === row);

    const centerX = (col * cellSize) + (cellSize / 2);
    const centerY = (row * cellSize) + (cellSize / 2);

    if (selectedCell && selectedCell.row === row && selectedCell.col === col) {
      setSelectedCell(null);
    } else {
      setSelectedCell({ row, col, tent, house, centerX, centerY });
    }
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStart({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const deltaX = event.clientX - dragStart.x;
      const deltaY = event.clientY - dragStart.y;
      setDragStart({ x: event.clientX, y: event.clientY });
      setCanvasOffset(prevOffset => {
        const newOffset = {
          x: prevOffset.x + deltaX,
          y: prevOffset.y + deltaY
        };
  
        // Отримання ширини та висоти канвасу
        const canvasWidth = canvasRef.current.offsetWidth; // чи інша відповідна властивість
        const canvasHeight = canvasRef.current.offsetHeight; // чи інша відповідна властивість
  
        // Отримання розміру видимої області
        const viewportWidth = window.innerWidth; // або інше джерело ширини видимого екрану
        const viewportHeight = window.innerHeight; // або інше джерело висоти видимого екрану
  
        // Обмеження по X
        newOffset.x = Math.max(Math.min(newOffset.x, 0), viewportWidth - canvasWidth);
  
        // Обмеження по Y
        newOffset.y = Math.max(Math.min(newOffset.y, 0), viewportHeight - canvasHeight);
  
        return newOffset;
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleBgVolumeChange = (value) => setBgVolume(parseFloat(value));
  const handleEffectsVolumeChange = (value) => setEffectsVolume(parseFloat(value));
  const handleOverallVolumeChange = (value) => setOverallVolume(parseFloat(value));


  const zoomIn = () => setZoomLevel(prevZoom => Math.min(prevZoom * 1.2, 5)); // максимальний зум 5x
  const zoomOut = () => setZoomLevel(prevZoom => Math.max(prevZoom / 1.2, 0.2)); // мінімальний зум 0.2x
  const resetZoom = () => setZoomLevel(1);

  return (
    <div className="game-field">
        <div className='container'>

        <div className='zomm-panel'>
          <h3>Панель зуму</h3>
        <button onClick={zoomIn}>+</button>
        <button onClick={zoomOut}>-</button>
        <button onClick={resetZoom}>Reset Zoom</button>
        </div>
       <ResourcePanel/>
        <SoundPanel 
        bgVolume={bgVolume}
          effectsVolume={effectsVolume}
          overallVolume={overallVolume}
          onBgVolumeChange={handleBgVolumeChange}
          onEffectsVolumeChange={handleEffectsVolumeChange}
          onOverallVolumeChange={handleOverallVolumeChange}
          onToggleSound={toggleSound}
          soundEnabled={soundEnabled}
          />
              <div>
                <h3>Збір</h3>
                <a href="https://send.monobank.ua/jar/2Mw5STLrJH" target="_blank">
                  <img 
                    src="./assets/images/qr_code_mono.jpg" 
                    alt="Mono Donation Widget" 
                  />
                </a>
            </div>
            <div>
            <div class="user-guide">
        <h3>Вітаю в грі!</h3>
        <p>Ваша місія — перетворювати донати на красиві будиночки та змінювати реальний світ на краще. Ось як грати:</p>
        <section>
            <li>Обирайте будиночок який вам до вподоби</li>
            <li>Закидуйте відповідну суму на банку</li>
            <li>Насолоджуйтесь результатом!</li>
        </section>
      </div>     
      </div>
        <div class="houses-container">
        <div class="house-item">
          <img src="./assets/images/winter_tent_1.png" alt="Tent"  style={{ width: '75px', height: '75px', objectFit: 'cover' }} />
          <p class="price">Від 30 грн до 99 грн</p>
        </div>
        <div class="house-item">
          <img src="./assets/images/winter_small_house1.png" alt="Small House"  style={{width: '75px', height: '75px', objectFit: 'cover' }}/>
          <p class="price">Від 100 грн до 199 грн</p>
        </div>
      </div>
      <div> <div class="house-item">
          <img src="./assets/images/winter_medium_house_1.png" alt="Medium House" style={{width: '75px', height: '75px', objectFit: 'cover' }}/>
          <p class="price">Від 200 грн до 349 грн</p>
        </div>
        <div class="house-item">
          <img src="./assets/images/winter_large_house_1.png" alt="Large House"  style={{ width: '75px', height: '75px', objectFit: 'cover' }}/>
          <p class="price">Від 350 грн</p>
        </div></div>
        </div>
      <canvas
        ref={canvasRef}
        width={columns * cellSize}
        height={rows * cellSize}
        onClick={(event) => {
          const rect = canvasRef.current.getBoundingClientRect();
          // Отримати координати кліка з урахуванням зуму
          const x = (event.clientX - rect.left - canvasOffset.x) / zoomLevel;
          const y = (event.clientY - rect.top - canvasOffset.y) / zoomLevel;
          
          // Розрахунок стовпця і рядка з урахуванням масштабу
          const col = Math.floor(x / (cellSize ));
          const row = Math.floor(y / (cellSize ));
          console.log(col,row)
          handleClick(event, row, col);
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      />
    </div>
    
  );
};

export default GameCanvas;
